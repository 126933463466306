import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';

// Emulators
// import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
// import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FirebaseConfig } from './configs/firebase.config';
import { CoreModule } from './core/core.module';
// import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(FirebaseConfig),
    CoreModule,
  ],
  providers: [
    // {
    //   provide: USE_AUTH_EMULATOR,
    //   useValue: environment.production ? undefined : ['localhost', 9099],
    // },
    // {
    //   provide: USE_FIRESTORE_EMULATOR,
    //   useValue: environment.production ? undefined : ['localhost', 8080],
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
